var playersInfo = [];

jQuery('.youtubePlayer').each( function(index) {

  var youtubeId = jQuery(this).data('youtube');

  playersInfo.push(
    {
      id: 'youtube' + youtubeId,
      height: '505',
      width: '853',
      videoId: youtubeId
    }
  );

});

var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

function createPlayer(playerInfo) {
  return new YT.Player( playerInfo.id, {
     videoId: playerInfo.videoId
  });
}

function bindHover(player) {
  jQuery(player.a).bind( 'mouseover touchstart', function(e) {
    player.playVideo();
  });
}

function onYouTubeIframeAPIReady() {
  if (typeof playersInfo === 'undefined'){
     return;
  }
  for(var i = 0; i < playersInfo.length;i++) {
    var curplayer = createPlayer(playersInfo[i]);

    bindHover(curplayer);

  }
}
